import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: `'Playfair Display', sans-serif`,
    body: `'Roboto', sans-serif`
  },
  colors: {
    chGreen: {
      'main': '#446423',
      'text': '#405D18',
      'background': '#DFE4D4',
    },
    chOrange: {
      500: '#E0600E',
      600: '#E0600E'
    },
    form: {
      'background': '#FBFCFD',
      'border': 'rgba(15, 39, 118, 0.1)'
    }
  },
  styles: {
    global: {
      'html, body': {
        backgroundColor: '#DFE4D4'
      }
    }
  }
})
