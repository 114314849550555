import { Box, Button, Container, Flex, Spacer } from "@chakra-ui/react"
import { Link, Outlet } from "react-router-dom"
import HamburgerIcon from "../components/icons/hamburger"
import Logo from "../components/icons/logo"


const Root = () => (
  <Container p={0} height="100vh">
    <Flex as="nav" w='100%' h={14} bg="chGreen.main" align="center" px={4} sx={{ position: "sticky" }} top="0" zIndex="9999">
      <HamburgerIcon />
      <Box ml={4}><Link to="/"><Logo /></Link></Box>
      <Spacer />
      <Button size="sm" colorScheme="chOrange" boxShadow="base">
        Connect Wallet
      </Button>
    </Flex>
    <Outlet />
  </Container >
)

export default Root
