import { Heading, Text } from "@chakra-ui/react";
import { InfoWindow, Marker, MarkerProps } from "@react-google-maps/api"
import { useId, useState } from "react"
import { Link, LinkProps } from "react-router-dom";


interface CustomMarkerProps extends Pick<MarkerProps, 'position'> {
  name: string;
  description: string
  to: LinkProps['to']
  defaultShow?: boolean
}

const CustomMarker = ({ position, name, description, to, defaultShow = false }: CustomMarkerProps) => {
  const id = useId()
  const [isOpen, setIsOpen] = useState(defaultShow);

  return (
    <Marker
      key={`marker-${id}`}
      icon={{
        path: 'M15.9901 3.1403C15.7212 2.87143 15.3922 2.6703 15.0303 2.55351C14.6684 2.43671 14.2839 2.40758 13.9086 2.46852C13.5332 2.52946 13.1777 2.67872 12.8713 2.90401C12.565 3.1293 12.3165 3.42416 12.1465 3.7643L10.0705 7.9151C9.8733 8.30888 9.53858 8.61671 9.1297 8.7803L4.8217 10.5035C4.63729 10.5773 4.47409 10.6957 4.34674 10.8482C4.21939 11.0006 4.13186 11.1823 4.09202 11.3768C4.05217 11.5714 4.06125 11.7729 4.11844 11.9631C4.17563 12.1533 4.27915 12.3264 4.4197 12.4667L7.5517 15.5999L3.6001 19.5503V20.3999H4.4485L8.4001 16.4471L11.5321 19.5791C11.6724 19.7197 11.8455 19.8232 12.0357 19.8804C12.2259 19.9376 12.4274 19.9466 12.622 19.9068C12.8165 19.8669 12.9982 19.7794 13.1506 19.6521C13.3031 19.5247 13.4215 19.3615 13.4953 19.1771L15.2185 14.8691C15.3824 14.46 15.6907 14.1253 16.0849 13.9283L20.2345 11.8523C20.5746 11.6823 20.8695 11.4338 21.0948 11.1275C21.3201 10.8211 21.4693 10.4656 21.5303 10.0902C21.5912 9.71486 21.5621 9.33038 21.4453 8.96849C21.3285 8.60659 21.1274 8.27762 20.8585 8.0087L15.9901 3.1403Z',
        strokeColor: '#D27C2C',
        fillColor: '#D27C2C',
        fillOpacity: 1
      }}
      position={position}
      onClick={() => setIsOpen(true)}
    >
      {isOpen &&
        (<InfoWindow key={`info-${id}`} position={position} onCloseClick={() => setIsOpen(false)}>
          <Link to={to}>
            <Heading as="h3" size="xs" color="chGreen.text" borderBottom="1px solid #595959" mb={2} pb={2}>{name}</Heading>
            <Text>{description}</Text>
          </Link>
        </InfoWindow>)
      }
    </Marker>
  )
}

export default CustomMarker
