import { Center, Heading, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const Home = () => (
  <Center as="section" flexDirection="column">
    <Heading mt="10" mb="4" color="chGreen.text" fontSize="85px" fontWeight="900" textAlign="center" textShadow="0px 4px 4px rgba(64, 93, 24, 0.5)">Cronos Harvest</Heading>
    <Heading as="h3" fontSize="2xl" mb="14" textAlign="center">Decentralising food supply</Heading>
    <Button as={Link} to="/discover" mb="10" size="sm" colorScheme="chOrange" variant="outline">Users Enter Here</Button>
    <Button size="sm" colorScheme="chOrange" variant="outline">Farmers Enter Here</Button>
  </Center >
)

export default Home
