import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"
import Globe from "react-globe.gl"
import { Form, Link, useSearchParams } from "react-router-dom"
import BackIcon from "../components/icons/back"
import SearchIcon from "../components/icons/search"
import CustomMarker from "../components/CustomMarker"
import { useEffect, useRef, useState } from "react"

const SelectLand = () => {
  const ref = useRef<HTMLElement>();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setHeight(ref.current?.offsetHeight || 0);
    setWidth(ref.current?.offsetWidth || 0);
  }, [])

  return (
    <Flex as="section" flexDirection="column" h="calc(100vh - 3.5em)" backgroundColor="black" position="relative" ref={ref as any}>
      <Flex direction="column" pos="absolute" zIndex="1" top={6} px={4} w="full">
        <Flex alignItems="center" mb={4}>
          <Link to={-1 as any}><BackIcon fill="#ffffff" /></Link>
          <Heading ml={2} color="white">Select Land</Heading>
        </Flex>
        <Form method="get" action="/discover">
          <FormControl>
            <FormLabel fontSize="xl" fontWeight="semibold" color="chGreen.background">Find your perfect location</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<SearchIcon />}
              />
              <Input name="location" type='search' placeholder="Search a location" bg="form.background" borderColor="form.border" borderRadius="sm" />
            </InputGroup>
          </FormControl>
        </Form>
      </Flex>
      <Box mb="-200px">
        <Globe width={width} height={height + 200} globeImageUrl="https://unpkg.com/three-globe@2.24.8/example/img/earth-day.jpg" />
      </Box>

    </Flex>
  )
}

const locationDict: Record<string, { lat: number, lng: number }> = {
  'thailand': {
    lat: 13.736717, lng: 100.523186
  }
}

const PickFarm = ({ location }: { location: string }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDN4nroD4XnsIsyfR-WGWZsbwmRoXKJrSk"
  })

  return isLoaded ?
    <Box height="calc(100vh - 3.5em)" position="relative">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={8}
        center={locationDict?.[location?.toLowerCase()] || locationDict['thailand']}
        mapTypeId="hybrid"
      >
        <CustomMarker to="/farm/happy-farm" position={locationDict['thailand']} name="Happy Farm" description="Famous for durian" defaultShow />
      </GoogleMap>
      <Center position="absolute" zIndex={1} bottom="0" pb={8} w="full">
        <Button colorScheme="chOrange" rightIcon={<BackIcon fill="white" sx={{ transform: 'rotate(180deg)' }} />}>See featured farms in the area</Button>
      </Center>
    </Box> : null
}

const Discover = () => {
  const [searchParams] = useSearchParams()
  const location = searchParams.get('location')

  return (
    !location ? <SelectLand /> : <PickFarm location={location} />
  )
}

export default Discover
