import { ChakraProvider } from "@chakra-ui/react"
import { theme } from "./theme"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Root from "./routes/root";

import Home from "./routes/home";
import Discover from "./routes/discover";
import MintStep1 from "./routes/mint/select-land";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/discover",
        element: <Discover />,
      }
    ],
  },
  // {
  //   path: "/mint",
  //   element: <Navigate to="/mint/step-1" replace />,
  //   children: [
  //     {
  //       path: "/step-1",
  //       element: <MintStep1 />,
  //     }
  //   ]
  // }
]);

export const App = () => (
  <ChakraProvider theme={theme}>
    <RouterProvider router={router} />
  </ChakraProvider >
)
